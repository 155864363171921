<template>
  <div class="wrapper">
    <logo />
    <titleDc :title="Utils.getUsername(this.friend)" :subtitle="'statistics about your calls with ' + this.friend" />
    <ul class="main-text friend-info-list">
      <li class="list-item"> <span><fa-icon class="icon" icon="fa-solid fa-phone" /> Amount of Calls:</span> &nbsp;<span class="extra-text">{{ this.amountCalled }}</span> </li>
      <li></li>
      <li class="list-item"> <span><fa-icon class="icon" icon="fa-solid fa-hourglass" /> Total Time in Call:</span> &nbsp;<span class="extra-text">{{ this.lengthCalled }}</span> </li>
      <li class="list-item"> <span><fa-icon class="icon" icon="fa-solid fa-hourglass" /> Average Call Duration:</span> &nbsp;<span class="extra-text">{{ this.avgLength }}</span> </li>
      <li></li>
      <li class="list-item"> <span><fa-icon class="icon" icon="fa-solid fa-stopwatch" /> Longest Call:</span> &nbsp;<span class="extra-text">{{ this.longestCall }}</span> </li>
      <li class="list-item"> <span><fa-icon class="icon" icon="fa-solid fa-stopwatch" /> Shortest Call:</span> &nbsp;<span class="extra-text">{{ this.shortestCall }}</span> </li>
      <li></li>
      <li class="list-item"> <span><fa-icon class="icon" icon="fa-solid fa-clock" /> Average Call Started:</span> &nbsp;<span class="extra-text">{{ this.avgStarted }}</span> </li>
      <li class="list-item"> <span><fa-icon class="icon" icon="fa-solid fa-clock" /> Average Call Ended:</span> &nbsp;<span class="extra-text">{{ this.avgEnded }}</span> </li>
    </ul>
  </div>
</template>

<script>
import logo from "@/components/Logo";
import titleDc from "@/components/Title";
import Utils from "@/utils";

export default {
  name: "FriendPage",
  components: {
    logo,
    titleDc
  },
  methods: {
    setCallsforUser(user) {
      const newCalls = []

      for (let i = 0; i < this.calls.length; i++) {
        let call = this.calls[i]
        if (call.call.participants.includes(user)) newCalls.push(call)
      }

      this.calls = newCalls
    },
    setAmountCalled(user) {
      let count = 0

      Utils.getDupeFriends(this.calls).forEach(friend => (friend === user && count++))

      this.amountCalled = count
    }
  },
  data() {
    return {
      Utils,
      calls: JSON.parse(localStorage.getItem('calls')),
      friend: '',
      lengthCalled: '',
      amountCalled: '',
      longestCall: '',
      shortestCall: '',
      avgStarted: '',
      avgEnded: '',
      avgLength: ''
    }
  },
  mounted() {
    this.friend = this.$route.params.name + '#' + this.$route.params.tag
    this.setCallsforUser(this.friend)
    this.lengthCalled = Utils.getHoursMinutesString(Utils.getTotalLengthInMins(this.calls))
    this.setAmountCalled(this.friend)
    this.longestCall = Utils.getHoursMinutesString(Utils.getCallByDuration(this.calls, true))
    this.shortestCall = Utils.getHoursMinutesString(Utils.getCallByDuration(this.calls, false))
    this.avgStarted = Utils.setAvgTime(this.calls, true)
    this.avgEnded = Utils.setAvgTime(this.calls, false)
    this.avgLength = Utils.getHoursMinutesString(Utils.getTotalLengthInMins(this.calls) / this.calls.length)
  }
}
</script>

<style lang="sass" scoped>
.wrapper
  background-color: $highlight-color
  width: 100vw
  min-height: 100vh
  display: flex
  flex-direction: column
  align-items: center
  overflow: hidden

.friend-info-list
  background-color: $bgr-color
  padding: 20px
  margin: 20px
  border-radius: 20px
  font-size: 22px

.extra-text
  font-size: 20px

.list-item
  background-color: $highlight-color
  padding: 20px
  margin: 10px
  border-radius: 20px
  display: flex
  justify-content: space-between
  align-items: center
</style>
