<template>
  <div class="wrapper">
    <logo />
    <titleDc title="Time" subtitle="statistics about the time you start and end calls" />
    <div class="timeInfo">
      <section class="total">
        <h2 class="main-text text-center total-title"> <fa-icon class="icon" icon="fa-solid fa-stopwatch" /> Total Time Spent in Call </h2>
        <p class="extra-text text-center total-amount"> {{ totalLength }} </p>
      </section>
      <div class="lower">
        <div>
          <section class="avgTime">
            <h2 class="main-text text-center"> <fa-icon class="icon" icon="fa-solid fa-gauge" /> Average Times </h2>
            <ul class="avgList">
              <li class="main-text list-item"> Average Started: &nbsp;<span class="extra-text">{{ avgStarted }}</span> </li>
              <li class="main-text list-item"> Average Ended: &nbsp;<span class="extra-text">{{ avgEnded }}</span> </li>
              <li class="main-text length list-item"> Average Duration: &nbsp;<span class="extra-text">{{ avgDuration }}</span> </li>
            </ul>
          </section>
        </div>
        <div>
          <section class="extTimes">
            <h2 class="main-text text-center"> <fa-icon class="icon" icon="fa-solid fa-gauge-high" /> Extremes </h2>
            <ul class="extList">
              <li class="main-text list-item"> Shortest Duration: &nbsp;<span class="extra-text">{{ shortest }}</span> </li>
              <li class="main-text list-item"> Longest Duration: &nbsp;<span class="extra-text">{{ longest }}</span> </li>
            </ul>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import titleDc from '@/components/Title'
import logo from '@/components/Logo'
import Utils from "@/utils";

export default {
  name: "FriendsPage",
  components: {
    titleDc,
    logo
  },
  data() {
    return {
      calls: [],
      totalLength: '',
      shortest: '',
      longest: '',
      avgDuration: '',
      avgStarted: '',
      avgEnded: ''
    }
  },
  mounted() {
    this.calls = JSON.parse(localStorage.getItem('calls'))
    this.longest = Utils.minutesToTimeStamp(Utils.getCallByDuration(this.calls, true))
    this.shortest = Utils.minutesToTimeStamp(Utils.getCallByDuration(this.calls, false))
    this.totalLength = Utils.getHoursMinutesString(Utils.getTotalLengthInMins(this.calls))
    this.avgDuration = Utils.minutesToTimeStamp(Math.floor(Utils.getTotalLengthInMins(this.calls) / this.calls.length))
    this.avgStarted = Utils.setAvgTime(this.calls, true)
    this.avgEnded = Utils.setAvgTime(this.calls, false)
  }
}
</script>

<style lang="sass" scoped>
.wrapper
  background-color: $highlight-color
  width: 100vw
  min-height: 100vh
  display: flex
  flex-direction: column
  align-items: center
  overflow: hidden

.timeInfo
  background-color: $bgr-color
  margin: 20px
  padding: 20px
  border-radius: 20px

.avgTime
  background-color: $highlight-color
  margin: 20px
  padding: 20px
  border-radius: 20px
  font-size: 22px

  &:hover
    .icon
      color: $blurple

.extTimes
  background-color: $highlight-color
  margin: 20px
  padding: 20px
  border-radius: 20px
  font-size: 22px

  &:hover
    .icon
      color: $blurple

.avgList
  background-color: $selected-color
  margin-top: 10px
  padding: 20px
  border-radius: 20px
  font-size: 20px

.length
  margin-top: 15px

.list-item
  display: flex
  justify-content: space-between

.extList
  background-color: $selected-color
  margin-top: 10px
  padding: 20px
  border-radius: 20px
  font-size: 20px

.lower
  display: flex

.total-title
  font-size: 36px

.total-amount
  font-size: 32px

.total
  background-color: $highlight-color
  margin-top: 10px
  padding: 20px
  border-radius: 20px

  &:hover
    .icon
      color: $blurple

.icon
  transition: 0.2s

@media only screen and (max-width: 768px)
  .wrapper
    overflow: unset
    position: unset
    height: unset

</style>
