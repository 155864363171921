<template>
  <div class="wrapper">
    <div class="loading-wrapper"><div class="loading-icon"></div></div>
    <logo />
    <titleDc title="Discord Call Stats" :subtitle="'drop your file anywhere on the screen to ' + hasDataWord + ' your call statistics'" />
    <section v-if="hasData()" id="cards">
      <card id="calls" icon="fa-phone" name="Calls" :amount="callAmount"></card>
      <card id="friends" icon="fa-user" name="Friends" :amount="friendAmount"></card>
      <card id="time" icon="fa-stopwatch" name="Time" :amount="startTime"></card>
    </section>
  </div>
</template>

<script>
import card from "@/components/Card"
import titleDc from "@/components/Title"
import logo from '@/components/Logo'
import swal from 'sweetalert'
import Utils from "@/utils";

export default {
  name: 'HomePage',
  components: {
    card,
    titleDc,
    logo
  },
  methods: {
    hasData() {
      let data = localStorage.getItem('calls');

      if (data !== null) {
        this.hasDataWord = 'update'
      }

      return data
    },
    stopEvent(e) {
      e.stopPropagation()
      e.preventDefault()
    }
  },
  mounted() {
    if (localStorage.getItem('calls')) {
      this.calls = JSON.parse(localStorage.getItem('calls'))
      this.callAmount = this.calls.length
      this.startTime = Utils.minutesToTimeStamp(Utils.minsFromDateString(this.calls[0].call.startTime))
      this.friendAmount = Utils.getUniqueFriends(this.calls).length
    }

    window.addEventListener('dragover', e => {
      this.stopEvent(e)

      document.getElementsByClassName('wrapper')[0].classList.add('drop-overlay')

      e.dataTransfer.dropEffect = 'copy'
    })

    window.addEventListener('dragleave', e => {
      this.stopEvent(e)

      document.getElementsByClassName('wrapper')[0].classList.remove('drop-overlay')
    })

    window.addEventListener('drop', e => {
      this.stopEvent(e)

      let droppedFiles = e.dataTransfer.files

      document.getElementsByClassName('wrapper')[0].classList.remove('drop-overlay')

      if (droppedFiles.length !== 1 || droppedFiles[0].type !== 'application/json') {
        swal('File Problem', 'Make sure you\'re using the correct file!')
        return
      }

      let reader = new FileReader()

      reader.addEventListener('load', e => {
        let result = JSON.parse(e.target.result);

        localStorage.setItem('calls', JSON.stringify(result.calls))

        location.reload()
      })

      reader.addEventListener('progress', e => {
        if (e.loaded && e.total) {
          const percent = (e.loaded / e.total) * 100
          if (percent === 100) {
            document.getElementsByClassName('loading-wrapper')[0].style.visibility = 'hidden'
          } else {
            document.getElementsByClassName('loading-wrapper')[0].style.visibility = 'visible'
          }
        }
      })

      reader.readAsText(droppedFiles[0])
    })
  },
  data() {
    return {
      hasDataWord: 'display',
      calls: '',
      startTime: '',
      friendAmount: '',
      callAmount: ''
    }
  }
}
</script>

<style lang="sass" scoped>
.wrapper
  background-color: $highlight-color
  width: 100vw
  min-height: 100vh
  display: flex
  flex-direction: column
  align-items: center
  overflow: hidden

.drop-overlay
  color: black
  opacity: 0.4

.loading-wrapper
  visibility: hidden
  display: flex
  justify-content: center
  align-items: center
  position: absolute
  height: 100vh
  width: 100vw
  background-color: rgba(0, 0, 0, 0.7)
  z-index: 2

.loading-icon
  display: inline-block
  width: 100px
  height: 100px

.loading-icon:after
  content: " "
  display: block
  width: 64px
  height: 64px
  margin: 8px
  border-radius: 50%
  border: 6px solid #fff
  border-color: #fff transparent #fff transparent
  animation: loading-icon 1.2s linear infinite

.swal-modal
  background-color: $bgr-color
  border-radius: 20px
  box-shadow: rgba(0, 0, 0, 0.1) 5px 5px 0

.swal-title, .swal-text
  color: $main-text-color

.swal-button
  background-color: $highlight-color
  transition: 0.3s
  border-radius: 10px

  &:hover
    background-color: $hover-color

#cards
  background-color: $bgr-color
  margin: 50px 0
  border-radius: 20px

@keyframes loading-icon
  0%
    transform: rotate(0deg)

  100%
    transform: rotate(360deg)

#cards
  display: flex
  z-index: 1

@media only screen and (max-width: 768px)
  .wrapper
    overflow: unset
    position: unset
    height: unset

  #cards
    flex-direction: column
    width: 100vw

</style>
