<template>
  <div class="wrapper">
    <logo />
    <titleDc title="Friends" subtitle="statistics about who you are in calls with" />
    <div class="friendsInfo">
      <div class="extFriends">
        <div>
          <section class="ext-call-info">
            <h2 class="main-text text-center"> <fa-icon class="icon-friends" icon="fa-solid fa-phone" /> Most Calls </h2>
            <p class="main-text ext-info-text"> <router-link class="pointer" :to="'/friends/' + Utils.getUsername(this.mostCalled.friend.toString()) + '/' + Utils.getUserTag(this.mostCalled.friend.toString()).substring(1)">{{ Utils.getUsername(this.mostCalled.friend.toString()) }}<span class="extra-text">{{ Utils.getUserTag(this.mostCalled.friend.toString()) }}</span></router-link> &nbsp;{{ this.mostCalled.amount }} </p>
          </section>
          <section class="ext-call-info">
            <h2 class="main-text text-center"> <fa-icon class="icon-friends" icon="fa-solid fa-phone" /> Least Calls </h2>
            <p class="main-text ext-info-text"> <router-link class="pointer" :to="'/friends/' + Utils.getUsername(this.leastCalled.friend.toString()) + '/' + Utils.getUserTag(this.leastCalled.friend.toString()).substring(1)">{{ Utils.getUsername(this.leastCalled.friend.toString()) }}<span class="extra-text">{{ Utils.getUserTag(this.leastCalled.friend.toString()) }}</span></router-link> &nbsp;{{ this.leastCalled.amount }} </p>
          </section>
        </div>
        <div>
          <section class="ext-call-info">
            <h2 class="main-text text-center"> <fa-icon class="icon-friends" icon="fa-solid fa-stopwatch" /> Most Time Spent </h2>
            <p class="main-text ext-info-text"> <router-link class="pointer" :to="'/friends/' + Utils.getUsername(this.mostSpent.friend.toString()) + '/' + Utils.getUserTag(this.mostSpent.friend.toString()).substring(1)">{{ Utils.getUsername(this.mostSpent.friend.toString()) }}<span class="extra-text">{{ Utils.getUserTag(this.mostSpent.friend.toString()) }}</span></router-link> &nbsp;{{ this.mostSpent.amount }} </p>
          </section>
          <section class="ext-call-info">
            <h2 class="main-text text-center"> <fa-icon class="icon-friends" icon="fa-solid fa-stopwatch" /> Least Time Spent </h2>
            <p class="main-text ext-info-text"> <router-link class="pointer" :to="'/friends/' + Utils.getUsername(this.leastSpent.friend.toString()) + '/' + Utils.getUserTag(this.leastSpent.friend.toString()).substring(1)">{{ Utils.getUsername(this.leastSpent.friend.toString()) }}<span class="extra-text">{{ Utils.getUserTag(this.leastSpent.friend.toString()) }}</span></router-link> &nbsp;{{ this.leastSpent.amount }} </p>
          </section>
        </div>
      </div>
      <section class="allFriendsInfo">
        <h2 class="main-text text-center"> <fa-icon class="icon-friends" icon="fa-solid fa-users" /> All Friends </h2>
        <ul>
          <li v-for="friend in this.friends" v-bind:key="friend">
            <router-link class="friend-item" :to="'/friends/' + Utils.getUsername(friend.toString()) + '/' + Utils.getUserTag(friend.toString()).substring(1)">
              <div class="main-text icon">
                <fa-icon icon="fa-solid fa-user" />
              </div>
              <div class="main-text">
                {{ Utils.getUsername(friend) }}<span class="extra-text">{{ Utils.getUserTag(friend) }}</span>
              </div>
            </router-link>
          </li>
        </ul>
      </section>
    </div>
  </div>
</template>

<script>
import titleDc from '@/components/Title'
import logo from '@/components/Logo'
import Utils from "@/utils";

export default {
  name: "FriendsPage",
  components: {
    titleDc,
    logo
  },
  methods: {
    setCallAmounts() {
      this.friends.forEach(friend => this.setAmountCalled(friend))
    },
    setAmountCalled(user) {
      let count = 0

      Utils.getDupeFriends(this.calls).forEach(friend => (friend === user && count++))

      this.amountCalled.set(user, count)
    },
    getMostCalled() {
      let amounts = []

      for (let i = 0; i < this.friends.length; i++) {
        amounts[i] = this.amountCalled.get(this.friends[i])
      }

      let highestAmount = Math.max(...amounts)

      this.mostCalled = {
        friend: this.getByValue(this.amountCalled, highestAmount),
        amount: highestAmount
      }
    },
    getLeastCalled() {
      let amounts = []

      for (let i = 0; i < this.friends.length; i++) {
        amounts[i] = this.amountCalled.get(this.friends[i])
      }

      let highestAmount = Math.min(...amounts)

      this.leastCalled = {
        friend: this.getByValue(this.amountCalled, highestAmount),
        amount: highestAmount
      }
    },
    getByValue(map, searchValue) {
      for (let [key, value] of map.entries()) {
        if (value === searchValue)
          return key;
      }
    },
    setTotalCallLengthForUser(user) {
      const newCalls = []

      for (let i = 0; i < this.calls.length; i++) {
        if (this.calls[i].call.participants.includes(user)) newCalls.push(this.calls[i])
      }

      this.lengthCalled.set(user, Utils.getTotalLengthInMins(newCalls))
    },
    setMostTimeSpent() {
      let times = []

      for (let i = 0; i < this.friends.length; i++) {
        times[i] = this.lengthCalled.get(this.friends[i])
      }

      let longest = Math.max(...times)

      let friend = this.getByValue(this.lengthCalled, longest)

      this.mostSpent = {
        friend: friend,
        amount: Utils.getHoursMinutesString(longest)
      }
    },
    setLeastTimeSpent() {
      let times = []

      for (let i = 0; i < this.friends.length; i++) {
        times[i] = this.lengthCalled.get(this.friends[i])
      }

      let shortest = Math.min(...times)

      let friend = this.getByValue(this.lengthCalled, shortest)

      this.leastSpent = {
        friend: friend,
        amount: Utils.getHoursMinutesString(shortest)
      }
    },
    setTimeSpent() {
      for (let i = 0; i < this.friends.length; i++) {
        this.setTotalCallLengthForUser(this.friends[i])
      }
    }
  },
  data() {
    return {
      Utils,
      calls: [],
      friends: [],
      amountCalled: new Map(),
      lengthCalled: new Map(),
      mostCalled: {
        friend: String,
        amount: Number
      },
      leastCalled: {
        friend: String,
        amount: Number
      },
      mostSpent: {
        friend: String,
        amount: Number
      },
      leastSpent: {
        friend: String,
        amount: Number
      }
    }
  },
  mounted() {
    this.calls = JSON.parse(localStorage.getItem('calls'))
    this.friends = Utils.getUniqueFriends(this.calls)
    this.setCallAmounts()
    this.getMostCalled()
    this.getLeastCalled()
    this.setTimeSpent()
    this.setMostTimeSpent()
    this.setLeastTimeSpent()
  }
}
</script>

<style lang="sass" scoped>
a
  all: unset

.wrapper
  background-color: $highlight-color
  width: 100vw
  min-height: 100vh
  display: flex
  flex-direction: column
  align-items: center
  overflow: hidden

.friendsInfo
  background-color: $bgr-color
  margin: 20px
  padding: 20px
  border-radius: 20px
  font-size: 22px

.allFriendsInfo
  background-color: $highlight-color
  margin: 20px
  padding: 20px
  border-radius: 20px

  &:hover
    .icon-friends
      color: $blurple

.friend-item
  background-color: $selected-color
  margin: 10px
  padding: 15px 20px
  border-radius: 20px
  font-size: 20px
  display: flex
  transition: 0.2s

  &:hover
    background-color: $hover-color
    cursor: pointer
    box-shadow: $shadow

    .icon
      color: $blurple

.icon
  transition: 0.2s
  margin-right: 10px

.icon-friends
  transition: 0.2s

.extFriends
  display: flex

.ext-call-info
  background-color: $highlight-color
  margin: 15px
  padding: 20px
  border-radius: 20px

  &:hover
    .icon-friends
      color: $blurple

.ext-info-text
  display: flex
  justify-content: space-between
  font-size: 20px

.pointer
  &:hover
    cursor: pointer

@media only screen and (max-width: 768px)
  .wrapper
    overflow: unset
    position: unset
    height: unset

</style>
