<template>
  <div>
    <router-link class="card-wrapper" :to="'/' + name.toLowerCase()">
      <div class="icon-wrapper"><fa-icon :icon="'fa-solid ' + icon" size="6x" /></div>
      <p class="amount"> {{ amount }} </p>
      <h2 class="name"> {{ name }} </h2>
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'CardItem',
  props: {
    amount: String,
    name: String,
    icon: String
  }
}
</script>

<style lang="sass" scoped>
a
  all: unset

.card-wrapper
  background-color: $highlight-color
  color: $main-text-color
  width: 300px
  margin: 50px
  height: 400px
  border-radius: 30px
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  transition: 0.2s

  &:hover
    background-color: $hover-color
    box-shadow: rgba(0, 0, 0, 0.1) 8px 8px 0
    cursor: pointer

    .icon-wrapper
      color: $blurple

.icon-wrapper
  margin-top: 30px
  transition: 0.2s

.name
  font-size: 30px

.amount
  font-size: 60px
  font-weight: bold
  margin-top: 60px

</style>
