<template>
  <router-link class="circle logo" to="/">
    <img src="@/assets/discord-mark-white.png" width="160" alt="Discord Logo">
  </router-link>
</template>

<script>
export default {
  name: "LogoItem"
}
</script>

<style lang="sass" scoped>
.circle
  display: flex
  justify-content: center
  align-items: center
  margin-top: 70px
  margin-bottom: 50px
  min-width: 250px
  min-height: 250px
  width: 250px
  height: 250px
  background-color: $blurple
  border-radius: 50%
  z-index: 1
  transition: 0.2s

  &:hover
    cursor: pointer
    box-shadow: $shadow

.logo
  z-index: 1
</style>
