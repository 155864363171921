<template>
  <div>
    <router-view></router-view>
    <footerDc />
  </div>
</template>

<script>
import footerDc from '@/components/Footer'

export default {
  name: 'App',
  components: {
    footerDc
  }
}
</script>

<style lang="sass">
*
  padding: 0
  margin: 0
  overflow-x: hidden !important
  font-family: Nunito Sans, sans-serif

html
  background-color: $highlight-color

.main-text
  color: $main-text-color

.extra-text
  color: $extra-text-color

.text-center
  text-align: center

::-webkit-scrollbar
  width: 10px

::-webkit-scrollbar-track
  background-color: $bgr-color
  border-radius: 10px

::-webkit-scrollbar-thumb
  background-color: $selected-color
  border-radius: 10px

</style>
