export default class Utils {
    static getUsername(user) {
        const pieces = user.split('#')

        return pieces[0]
    }

    static getUserTag(user) {
        const pieces = user.split('#')

        return '#' + pieces[1]
    }

    static removeUser(users, userToRemove) {
        users = users.filter(friend => friend !== userToRemove)
        return users
    }

    static getStartTime(call) {
        return new Date(call.call.startTime)
    }

    static getEndTime(call) {
        return new Date(call.call.endTime)
    }

    static getCallLengthInMins(call) {
        return Math.floor((Utils.getEndTime(call) - Utils.getStartTime(call)) / 1000 / 60)
    }

    static minutesToTimeStamp(timeInMins) {
        const times = Utils.minutesToHrsMins(timeInMins)

        let hrs = times.get('hrs')
        let mins = times.get('mins')

        hrs = ('0' + hrs).slice(-2)
        mins = ('0' + mins).slice(-2)

        return hrs + ':' + mins
    }

    static minutesToHrsMins(timeInMins) {
        const map = new Map()

        const hrs = Math.floor(timeInMins / 60)
        const mins = timeInMins - (hrs * 60)

        map.set('hrs', hrs)
        map.set('mins', mins)

        return map
    }

    static getHoursMinutesString(timeInMins) {
        const times = Utils.minutesToHrsMins(timeInMins)

        const hrs = times.get('hrs')
        const mins = times.get('mins')

        let stringBuilder = []

        if (hrs === 1) {
            stringBuilder.push(hrs, ' hour')
        } else if (hrs !== 0) {
            stringBuilder.push(hrs, ' hours')
        }

        stringBuilder.push(' ')

        if (mins === 1) {
            stringBuilder.push(mins, ' minute')
        } else if (mins !== 0) {
            stringBuilder.push(mins, ' minutes')
        }

        return stringBuilder.join('').trim()
    }

    static getCallLengthAsTimeStamp(call) {
        return Utils.minutesToTimeStamp(Utils.getCallLengthInMins(call))
    }

    static getCallLengthAsHrsMins(call) {
        return Utils.minutesToTimeStamp(Utils.getCallLengthAsHrsMins(call))
    }

    static getCallLengths(calls) {
        const lengthsMins = []

        for (let i = 0; i < calls.length; i++) {
            lengthsMins[i] = Utils.getCallLengthInMins(calls[i])
        }

        return lengthsMins
    }

    static getTotalLengthInMins(calls) {
        const lengthsMins = Utils.getCallLengths(calls)

        return lengthsMins.reduce((sum, add) => sum + add, 0)
    }

    static minsFromDateString(date) {
        const time = new Date(date)

        return (time.getHours() * 60) + time.getMinutes()
    }

    static getDupeFriends(calls) {
        const friendDupes = []

        for (let i = 0; i < calls.length; i++) {
            for (let j = 0; j < calls[i].call.participants.length; j++) {
                friendDupes[friendDupes.length] = calls[i].call.participants[j]
            }
        }

        return friendDupes.filter(friend => friend !== calls[0].callerId)
    }

    static getUniqueFriends(calls) {
        return Array.from(new Set(Utils.getDupeFriends(calls)))
    }

    static getCallStarted(call) {
        let date = call.call.startTime.substring(0, 10)
        let time = call.call.startTime.substring(11, 16)

        return date + ' ' + time
    }

    static getCallByDuration(calls, longestCall) {
        const lengthsMins = Utils.getCallLengths(calls)
        let result

        if (longestCall) {
            result = Math.max(...lengthsMins)
        } else {
            result = Math.min(...lengthsMins)
        }

        return result
    }

    static setAvgTime(calls, startTime) {
        let times = []

        for (let i = 0; i < calls.length; i++) {
            if (startTime) {
                times[i] = Utils.minsFromDateString(calls[i].call.startTime)
            } else {
                times[i] = Utils.minsFromDateString(calls[i].call.endTime)
            }
        }

        const total = times.reduce((prev, add) => prev + add, 0)

        return Utils.minutesToTimeStamp(Math.floor(total / times.length))
    }
}
