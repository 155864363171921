import { createApp } from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { createRouter, createWebHistory } from 'vue-router'

import { faPhone } from '@fortawesome/free-solid-svg-icons'
import { faUser } from '@fortawesome/free-solid-svg-icons'
import { faRulerHorizontal } from '@fortawesome/free-solid-svg-icons'
import { faStopwatch } from '@fortawesome/free-solid-svg-icons'
import { faMugHot } from '@fortawesome/free-solid-svg-icons'
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faGears } from '@fortawesome/free-solid-svg-icons'
import { faComments } from '@fortawesome/free-solid-svg-icons'
import { faGaugeHigh } from '@fortawesome/free-solid-svg-icons'
import { faGauge } from '@fortawesome/free-solid-svg-icons'
import { faUsers } from '@fortawesome/free-solid-svg-icons'
import { faClock } from '@fortawesome/free-solid-svg-icons'
import { faHourglass } from '@fortawesome/free-solid-svg-icons'
import { faDiscord } from '@fortawesome/free-brands-svg-icons'
import { faLinkedin } from '@fortawesome/free-brands-svg-icons'
import { faPaypal } from '@fortawesome/free-brands-svg-icons'

import App from "@/App";
import Home from "@/pages/Home";
import Friends from "@/pages/Friends";
import Calls from "@/pages/Calls";
import Time from "@/pages/Time";
import Call from "@/pages/subpages/Call";
import Friend from "@/pages/subpages/Friend";

library.add(faPhone, faUser, faRulerHorizontal, faStopwatch, faDiscord, faMugHot, faCircleInfo, faEnvelope, faLinkedin, faGears, faComments, faPaypal, faGaugeHigh, faGauge, faUsers, faClock, faHourglass)

const routes = [
    { path:'/', component: Home },
    { path:'/calls', component: Calls },
    { path:'/calls/:id', component: Call },
    { path:'/friends', component: Friends },
    { path:'/friends/:name/:tag', component: Friend },
    { path:'/time', component: Time }
]

const router = createRouter({
    history: createWebHistory(),
    routes
})

createApp(App)
    .use(router)
    .component("fa-icon", FontAwesomeIcon)
    .mount('#app')
