<template>
  <div class="wrapper">
    <logo />
    <titleDc :title="Utils.getUsername(this.call.callerId)" :subtitle="getParticipantNames(this.call)" />
    <div class="callInfo">
      <div>
        <section class="participantInfo">
          <h2 class="main-text text-center"> <fa-icon class="icon" icon="fa-solid fa-comments" /> Participants </h2>
          <ul class="participantsList">
            <li class="participant" v-for="user in this.call.call.participants" v-bind:key="user">
              <span class="main-text">{{ Utils.getUsername(user) }}</span><span class="extra-text">{{ Utils.getUserTag(user) }}</span>
            </li>
          </ul>
        </section>
      </div>
      <div>
        <section class="timeInfo">
          <h2 class="main-text text-center"> <fa-icon class="icon" icon="fa-solid fa-stopwatch" /> Time Information </h2>
          <ul class="timeList">
            <li class="main-text list-item"> Started: &nbsp;<span class="extra-text">{{ Utils.getCallStarted(this.call) }}</span> </li>
            <li class="main-text list-item"> Ended: &nbsp;<span class="extra-text">{{ this.getCallEnded(this.call) }}</span> </li>

            <li class="length main-text list-item"> Lasted: &nbsp;<span class="extra-text">{{ duration }}</span> </li>
          </ul>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import logo from "@/components/Logo";
import titleDc from "@/components/Title";
import Utils from "@/utils";

export default {
  name: "CallPage",
  components: {
    logo,
    titleDc
  },
  methods: {
    setCall() {
      for (let i = 0; i < this.calls.length; i++) {
        if (this.calls[i].id === this.$route.params.id) {
          this.call = this.calls[i]
        }
      }
    },
    getCallEnded(call) {
      let date = call.call.endTime.substring(0, 10)
      let time = call.call.endTime.substring(11, 16)

      return date + ' ' + time
    },
    getParticipantNames(call) {
      let namesString = ''

      for (let i = 0; i < call.call.participants.length; i++) {
        namesString = namesString + Utils.getUsername(call.call.participants[i]) + ', '
      }

      namesString = namesString.substring(0, namesString.length - 2)

      return namesString
    }
  },
  data() {
    return {
      Utils,
      id: '',
      calls: [],
      duration: '',
      call: {
        id: '',
        callerId: '',
        call: {
          startTime: '',
          endTime: '',
          participants: []
        }
      }
    }
  },
  mounted() {
    this.id = this.$route.params.id
    this.calls = JSON.parse(localStorage.getItem('calls'))
    this.setCall()
    this.duration = Utils.getHoursMinutesString(Utils.getCallLengthInMins(this.call))
  }
}
</script>

<style lang="sass" scoped>
.icon
  transition: 0.2s

.wrapper
  background-color: $highlight-color
  width: 100vw
  min-height: 100vh
  display: flex
  flex-direction: column
  align-items: center
  overflow: hidden

.participantInfo
  background-color: $highlight-color
  margin: 20px
  padding: 20px
  border-radius: 20px
  font-size: 22px

  &:hover
    .icon
      color: $blurple


.participantsList
  background-color: $selected-color
  margin-top: 10px
  padding: 20px
  border-radius: 20px
  font-size: 20px

.timeInfo
  background-color: $highlight-color
  margin: 20px
  padding: 20px
  border-radius: 20px
  font-size: 22px

  &:hover
    .icon
      color: $blurple

.timeList
  background-color: $selected-color
  margin-top: 10px
  padding: 20px
  border-radius: 20px
  font-size: 20px

.callInfo
  display: flex
  background-color: $bgr-color
  margin-top: 30px
  padding: 10px
  border-radius: 20px

.length
  margin-top: 15px

.list-item
  display: flex
  justify-content: space-between

@media only screen and (max-width: 768px)
  .wrapper
    overflow: unset
    position: unset
    height: unset
</style>
