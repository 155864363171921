<template>
  <footer>
    <section class="name">
      <router-link to="/">
        <div class="main-text name-text">
          <fa-icon class="text-center icon" :icon="'fa-brands ' + 'fa-discord'" :size="determineLogoSize()" />
          <h3 class="text-center"> &nbsp;Discord Call Stats </h3>
        </div>
      </router-link>
    </section>
    <section class="details">
      <ul>
        <li class="main-text item-title"> Donations </li>
        <li class="extra-text item-text"> <a href="https://ko-fi.com/isensehostility" target="_blank"> <fa-icon class="icon" icon="fa-solid fa-mug-hot" /> Ko-fi </a> </li>
        <li class="extra-text item-text"> <a href="https://paypal.me/isensehostility" target="_blank"> <fa-icon class="icon" icon="fa-brands fa-paypal" /> Paypal </a> </li>
      </ul>
      <ul>
        <li class="main-text item-title"> Contact </li>
        <li class="extra-text item-text"> <a href="" target="_blank"> <fa-icon class="icon" icon="fa-brands fa-discord" /> Discord </a> </li>
        <li class="extra-text item-text"> <a href="" target="_blank"> <fa-icon class="icon" icon="fa-brands fa-linkedin" /> LinkedIn </a> </li>
        <li class="extra-text item-text"> <a href="mailto:jarnovdmeer02@gmail.com" target="_blank"> <fa-icon class="icon" icon="fa-solid fa-envelope" /> E-mail </a> </li>
      </ul>
      <ul>
        <li class="main-text item-title"> Company </li>
        <li class="extra-text item-text"> <a href="" target="_blank"> <fa-icon class="icon" icon="fa-solid fa-circle-info" /> About Us </a> </li>
        <li class="extra-text item-text"> <a href="" target="_blank"> <fa-icon class="icon" icon="fa-solid fa-gears" /> Projects </a> </li>
        <li class="extra-text item-text"> <a href="" target="_blank"> <fa-icon class="icon" icon="fa-solid fa-comments" /> Blog </a> </li>
      </ul>
    </section>
  </footer>
</template>

<script>
export default {
  name: "FooterItem",
  methods: {
    determineLogoSize() {
      if (window.innerWidth < 768) return "2x"
      return "1x"
    }
  }
}
</script>

<style lang="sass" scoped>
footer
  background-color: #232428
  display: flex
  flex-direction: row

a
  all: unset

  &:hover
    cursor: pointer

ul
  margin: 40px 40px

.name
  margin-left: 300px
  width: 400px

.name-text
  display: flex
  justify-content: center
  align-items: center
  font-size: 25px
  margin: 40px 50px
  transition: 0.2s

  &:hover
    cursor: pointer

    .icon
      color: $blurple

.icon
  transition: 0.2s

.details
  display: flex
  flex-direction: row-reverse
  flex-grow: 1
  margin-right: 300px

.item-title
  font-size: 22px

.item-text
  font-size: 19px

  &:hover
    .icon
      color: $blurple

.link
  display: flex
  align-items: center
  justify-content: center

@media only screen and (max-width: 768px)
  footer
    flex-direction: column

  .name
    margin-left: unset
    width: 100vw

  .name-text
    font-size: 20px
    flex-direction: column
    margin: 20px 0

  .details
    flex-direction: column
    flex-grow: unset
    justify-content: center
    width: 100vw

  ul
    margin: 20px 20px

</style>
