<template>
  <section class="title">
    <h1 class="main-text text-center main-title"> {{ title }} </h1>
    <p class="extra-text text-center main-subtitle"> <span v-if="getWidth() > 768">&nbsp;-</span> {{ subtitle }} </p>
  </section>
</template>

<script>
export default {
  name: 'TitleItem',
  props: {
    title: String,
    subtitle: String
  },
  methods: {
     getWidth() {
       return window.innerWidth
     }
  }
}
</script>

<style lang="sass" scoped>
h1
  font-weight: bold

.title
  display: flex
  justify-content: center
  align-items: center
  z-index: 1

.main-title
  font-size: 40px

.main-subtitle
  font-size: 30px

@media only screen and (max-width: 768px)
  .title
    flex-direction: column

  .main-subtitle
    font-size: 20px
    height: 100px
</style>
