<template>
  <div class="wrapper">
    <logo />
    <titleDc title="Calls" subtitle="general statistics about your calls" />
    <ul class="callList">
      <li v-for="callObj in calls" v-bind:key="callObj.id">
        <router-link class="callItem" :to="'/calls/' + callObj.id">
          <div class="callIcon">
            <fa-icon icon="fa-solid fa-phone" size="3x" />
          </div>
          <div class="left-half">
            <p class="caller"> {{ Utils.getUsername(callObj.callerId) }}<span class="extra-text">{{ Utils.getUserTag(callObj.callerId) }} </span></p>

            <ul class="callParticipants">
              <li v-for="user in Utils.removeUser(callObj.call.participants, callObj.callerId)" v-bind:key="user">
                <p> {{ Utils.getUsername(user) }}<span class="extra-text">{{ Utils.getUserTag(user) }} </span> </p>
              </li>
            </ul>
          </div>
          <div class="right-half">
            <p class="callTimes"> {{ Utils.getCallStarted(callObj) }} </p>
          </div>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import titleDc from '@/components/Title'
import logo from '@/components/Logo'
import Utils from "@/utils";

export default {
  name: "CallsPage",
  components: {
    titleDc,
    logo
  },
  data () {
    return {
      calls: [],
      Utils
    }
  },
  mounted() {
    this.calls = JSON.parse(localStorage.getItem('calls'))
  }
}
</script>

<style lang="sass" scoped>
a
  all: unset

.wrapper
  background-color: $highlight-color
  width: 100vw
  min-height: 100vh
  display: flex
  flex-direction: column
  align-items: center
  overflow: hidden

.callList
  background-color: $bgr-color
  border-radius: 20px
  margin-top: 50px
  margin-bottom: 50px

.callItem
  margin: 25px
  padding: 20px
  border-radius: 20px
  background-color: $highlight-color
  color: $main-text-color
  display: flex
  min-width: 800px
  transition: 0.2s

  &:hover
    background-color: $hover-color
    cursor: pointer
    box-shadow: $shadow

    .callIcon
      color: $blurple

.callTimes
  display: flex
  flex-direction: column
  font-size: 18px
  transition: 0.2s

.callParticipants
  margin-top: 20px
  font-size: 18px

.caller
  font-size: 22px
  font-weight: bold

.callIcon
  display: flex
  align-items: center
  justify-content: center
  width: 60px
  padding-right: 20px
  transition: 0.2s

.left-half
  display: flex
  flex-direction: column

.right-half
  display: flex
  flex-direction: row-reverse
  align-items: flex-start
  flex-grow: 1

@media only screen and (max-width: 768px)
  .wrapper
    overflow: unset
    position: unset
    height: unset

</style>
